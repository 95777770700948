<template>
  <b-form-invalid-feedback
    v-if="message.valid"
    :state="!message.valid"
  >
    {{ message.content }}
  </b-form-invalid-feedback>
</template>
<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => ({
        valid: true,
        content: ''
      })
    }
  }
}
</script>
