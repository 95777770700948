<template>
  <div>
    <div
      class="table-responsive datatable-vue"
      :class="overflowEnabled ? 'table-responsive-overflow' : ''"
    >
      <b-table
        id="dataTableId"
        show-empty
        fixed
        striped
        stacked="lg"
        class="dataTable"
        :hover="false"
        :borderless="true"
        :style="cssVars"
        :bordered="false"
        :items="items"
        :busy="isBusy"
        :empty-text="$t(emptyText)"
        :empty-filtered-text="$t('components.doinsport.table.empty-filtred-text')"
        :fields="tablefields"
        :current-page="currentPage"
        :per-page="perPage"
        @row-clicked="onRowClick"
      >
        <template v-slot:table-busy>
          <busy-table/>
        </template>
        <template v-if="hasCheckbox" v-slot:head(preview)="data">
          <head-check-box/>
        </template>
        <template v-slot:head(iconLabel)="data">
          <head-icon-label
            :custom-data="data"
          />
        </template>
        <template v-slot:head(collapseLabel)="data">
          <head-collapse-label
            :custom-data="data"
            @on:icon-click="$emit('on:icon-click', data)"
          />
        </template>
        <template v-if="hasPhoto" v-slot:head(photo)="data">
          <head-photo-label
            :label="data.label"
          />
        </template>
        <template v-slot:cell(participantAvatar)="row">
          <cell-planning-participant-avatar
            :photo="row.item.participantAvatar"
          />
        </template>
        <template v-slot:cell(historyPaymentTokenCreditValue)="row">
          <cell-client-history-payment-token-credit-value
            :value="row.item.historyPaymentTokenCreditValue"
          />
        </template>
        <template v-slot:cell(collapseLabel)="row">
          <cell-collapse-label
            :label="row.item.collapseLabel"
            @on:check="$emit('on:check', row.item)"
          />
        </template>
        <template v-slot:cell(participantPaymentStatus)="row">
          <cell-planning-participant-payment-status
            :participantPayment="row.item.participantPaymentStatus"
          />
        </template>
        <template v-slot:cell(compaignStatus)="row">
          <cell-compaign-status
            :compaign-status="row.item.compaignStatus"
          />
        </template>
        <template v-slot:cell(campaignSendingValue)="row">
          <cell-campaign-value
            :custom-data="row.item.campaignSendingValue"
          />
        </template>
        <template v-if="hasCheckbox" v-slot:cell(preview)="row">
          <cell-preview-check-box
            :photo="row.item.preview"
          />
        </template>
        <template v-slot:cell(photo)="row">
          <cell-preview
            :photo="row.item.photo"
            @on:upload-playground-photo="onPlaygroundPhotoUploaded($event, row.item)"
            @on:delete-playground-photo="$emit('on:delete-playground-photo', row.item)"
          />
        </template>
        <template v-slot:cell(availability)="row">
          <cell-playground-availability
            :availability="row.item.availability"
            @on:playground:enabled-change="$emit('on:playground:enabled-change', row.item)"
          />
        </template>
        <template v-slot:cell(status)="row">
          <cell-bookings-status
            :status="row.item.status"
          />
        </template>
        <template v-slot:cell(pay)="row">
          <cell-planning-actions
            :payment-data="row.item"
            :booking="row.item.booking"
            @on:detail_booking_reload="$emit('on:detail_booking_reload')"
          />
        </template>
        <template v-slot:cell(participant)="row">
          <cell-planning-delete
            :iri="row.item.iri"
            @on:row-remove="onRowRemove"
          />
        </template>
        <template v-slot:cell(block)="row">
          <cell-time-zone-block
            :block="row.item.block"
          />
        </template>
        <template v-slot:cell(paymentstatus)="row">
          <cell-payment-status :payment-status="row.item.paymentStatus"/>
        </template>
        <template v-slot:cell(participants)="row">
          <cell-events-participants
            :participants="row.item.participants"
          />
        </template>
        <template v-slot:cell(book)="row">
          <cell-events-book/>
        </template>
        <template v-slot:cell(bill)="row">
          <cell-payment-bill
            :invoice-url="row.item.bill"
          />
        </template>
        <template v-slot:cell(subscription)="row">
          <cell-client-subscription
            :subscription="row.item.subscription"
          />
        </template>
        <template v-slot:cell(singleSelection)="row">
          <cell-single-selection
            :row="row.item"
            @on:radio-button-check="onRadioCheck"
          />
        </template>
        <template v-slot:cell(singleSelectionRate)="row">
          <cell-single-selection-rate
            :row="row.item"
            @on:radio-button-rate-check="onRadioRateCheck"
          />
        </template>
        <template v-slot:cell(customEdit)="row">
          <cell-playground-custom-edit-button/>
        </template>
        <template v-slot:cell(plusIcon)="row">
          <cell-plus-icon
            :selection="row.item.plusIcon"
            @on:plus-icon-click="$emit('on:plus-icon-click', row.item)"
          />
        </template>
        <template v-slot:cell(minusIcon)="row">
          <cell-minus-icon
            :selection="row.item.minusIcon"
            @on:minus-icon-click="$emit('on:minus-icon-click', row.item)"
          />
        </template>
        <template v-slot:cell(labelWithDate)="row">
          <cell-label-date
            :label="row.item.labelWithDate.label"
            :date="row.item.labelWithDate.date"
          />
        </template>
        <template v-slot:cell(timetables)="row">
          <cell-playground-timetables
            :timetables="row.item.timetables"
          />
        </template>
        <template v-slot:cell(daysOfTheWeek)="row">
          <cell-days-of-the-week
            :days="row.item.daysOfTheWeek"
          />
        </template>
        <template v-slot:cell(actions)="row">
          <cell-actions
            :item="row.item"
            :is-deleting="isDeleting"
            @on:data-table-btn:delete="onBtnDeleteClick"
            @on:data-table-btn:edit="onBtnEditClick"
            @on:data-table-btn:radio="onBtnDataTableCheck"
            @on:data-table-btn:checkbox="onBtnDataTableCheckbox"
          />
        </template>
        <template v-slot:cell(duration)="row">
          <cell-bookings-duration
            :startAt="row.item.startAt"
            :endAt="row.item.endAt"
          />
        </template>
        <template v-slot:cell(createdAt)="row">
          <cell-bookings-date
            :date="row.item.createdAt"
          />
        </template>
        <template v-slot:cell(fromDate)="row">
          <cell-from-date
            :from-date="row.item.fromDate"
          />
        </template>
        <template v-slot:cell(toDate)="row">
          <cell-to-date
            :to-date="row.item.toDate"
          />
        </template>
        <template v-slot:cell(startAt)="row">
          <cell-block-start-at
            :item="row.item"
          />
        </template>
        <template v-slot:cell(endAt)="row">
          <cell-block-end-at
            :item="row.item"
          />
        </template>
        <template v-slot:cell(user_client_step_booking_duration)="row">
          <cell-block-step-client-booking-duration
            :item="row.item"
          />
        </template>
        <template v-slot:cell(user_club_step_booking_duration)="row">
          <cell-block-step-club-booking-duration
            :item="row.item"
          />
        </template>
        <template v-slot:cell(name)="row">
          <cell-block-label
            :item="row.item"
          />
        </template>
        <template v-slot:cell(instalmentPercentage)="row">
          <cell-block-instalment-percentage
            :item="row.item"
          />
        </template>
        <template v-slot:cell(activity)="row">
          <cell-playground-activity
            :value="row.item.activity.name"
            :values="row.item.activity"
          />
        </template>
        <template v-slot:cell(playgrounds)="row">
          <cell-playgrounds-badge :playgrounds="row.item.playgrounds"/>
        </template>
        <template v-slot:cell(playgroundBookingActivity)="row">
          <cell-playgrounds-badge
            :playgrounds="row.item.playgroundBookingActivity"
            :is-badged="false"
          />
        </template>
        <template v-slot:cell(price)="row">
          <cell-price
            :price="row.item.price"
          />
        </template>
        <template v-slot:cell(regulation)="row">
          <cell-regulation
            :regulation="row.item.regulation"
          />
        </template>
        <template v-slot:cell(selection)="row">
          <cell-selection-checkbox
            :row="row.item"
            @on:checkbox-selected="onCheckboxSelectionSelected"
          />
        </template>
        <template v-slot:cell(otherPrices)="row">
          <cell-link
            :title="row.item.otherPrices"
            link-class="link-blue-light"
            @on:link-click="$emit('on:link-click', row.item)"
          />
        </template>
        <template v-slot:cell(qte)="row">
          <cell-custom
            :value="row.item.qte"
            class-name="text-dark-blue"
          />
        </template>
        <template v-slot:cell(calendarStatus)="row">
          <cell-playground-badge-status
            :row="row.item"
            @on:calendar-badge-update="$emit('on:calendar-badge-update', row.item)"
          />
        </template>
        <template v-slot:cell(pricePerParticipant)="row">
          <cell-custom
            :value="row.item.pricePerParticipant + currency"
          />
        </template>
        <template v-slot:cell(paymentMethods)="row">
          <cell-payment-methods
            :paymentMethods="row.item.paymentMethods"
          />
        </template>
        <template v-slot:cell(items)="row">
          <cell-credits-pack-items
            :items="row.item.items"
          />
        </template>
        <template v-slot:cell(availableDefault)="row">
          <cell-star
            :star-value="row.item.availableDefault"
          />
        </template>
        <template v-slot:cell(transactionDate)="row">
          <cell-custom
            :value="row.item.transactionDate"
            class-name="text-dark-blue"
          />
        </template>
        <template v-slot:cell(select)="row">
          <cell-select
            :options="row.item.select"
            @on:select-option:change="$emit('on:select-option:change', $event)"
          />
        </template>
      </b-table>
    </div>

    <b-row v-if="showPagination" class="mt-3 p-0">
      <b-col
        v-if="perPageEnabled"
        :cols="$store.getters['layout/getInnerWidth'] > 800 ? '' : '12'"
      >
        <div
          class="d-flex align-items-center per-page"
          :class="$store.getters['layout/getInnerWidth'] > 800 ? '' : 'per-page-mobile justify-content-center'"
        >
            <span
              class="per-page-label"
              :class="$store.getters['layout/getInnerWidth'] > 800 ? '' : 'pl-3 pr-5'"
            >
              {{ $t('components.doinsport.table.per-page') }}
            </span>
          <b-form-select
            class="d-flex side-panel ml-2"
            v-model="perPageCurrent"
            :options="pageOptions"
            @change="onPerPageUpdate"
          />
        </div>
      </b-col>
      <b-col>
        <b-pagination
          v-model="page"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
          align="center"
          size="sm"
        ></b-pagination>
      </b-col>
      <b-col
        class="current-progress d-flex"
        :class="$store.getters['layout/getInnerWidth'] > 800 ? 'justify-content-end' : 'justify-content-center'"
        :cols="$store.getters['layout/getInnerWidth'] > 800 ? '' : '12'"
      >
        {{ currentShow }}
      </b-col>
    </b-row>
    <d-modal
      :modalId="identifier"
      :display="display"
      :title="deleteModalTitle"
      :text="deleteModalDescription"
      :hide="hide"
      @on:cancel="onCancel"
      @on:confirm="onConfirm"
    />
  </div>
</template>

<script>
import {tableComponents} from "@/utils/table";

export default {
  components: tableComponents,
  props: {
    overflowEnabled: {
      type: Boolean,
      default: false,
    },
    identifier: {
      type: String,
      default: 'data-table-btn',
    },
    emptyText: {
      type: String,
      default: 'components.doinsport.table.empty-text',
    },
    trCursorPointer: {
      type: Boolean,
      default: false,
    },
    deleteModalTitle: {
      type: String,
      default: 'general.actions.delete-modal-title'
    },
    deleteModalDescription: {
      type: String,
      default: 'general.actions.delete-modal-text'
    },
    tablefields: {
      type: Array,
      default: () => [],
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    isBusy: {
      type: Boolean,
      default: true,
    },
    hasCheckbox: {
      type: Boolean,
      default: true,
    },
    perPageEnabled: {
      type: Boolean,
      default: false,
    },
    hasPhoto: {
      type: Boolean,
      default: true,
    },
    totalRows: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filter: null,
      isDeleting: false,
      deleteScope: null,
      perPageCurrent: 10,
      pageCurrent: 1,
      removeScope: null,
      display: false,
      hide: false,
      timer: null,
      pageOptions: [10, 25, 50, 100, 500], // Tous Todo
    };
  },
  computed: {
    cssVars() {
      if (this.trCursorPointer) {
        return {
          '--cursor': 'pointer',
          '--hover': '#f1f3f5'
        }
      } else {
        return {
          '--cursor': 'unset',
          '--hover': '#FFF'
        }
      }
    },
    currency() {
      return this.$store.getters['currency/getCurrency'][JSON.parse(localStorage.getItem('current-club')).currency];
    },
    currentShow() {
      let from = (this.pageCurrent * this.perPageCurrent) - this.perPageCurrent + 1;
      let to = this.pageCurrent * this.perPageCurrent;

      if (to > this.totalRows) {
        to = this.totalRows;
      }

      return this.$t('components.doinsport.table.current', {from, to, total: this.totalRows});
    },
    page: {
      get() {
        return this.currentPage;
      },
      set(page) {
        this.pageCurrent = page;
        this.$emit('on:pagination-model:update', page);
        this.$emit('current-page:update', page);
      },
    },
    perPageModel: {
      get() {
        return this.perPage;
      },
      set(perPageModel) {
        this.$emit('per-page:update', perPageModel);
      },
    },
    sortOptions() {
      return this.tablefields
        .filter((f) => f.sortable)
        .map((f) => {
          return {text: f.label, value: f.key};
        });
    },
  },
  methods: {
    onPerPageUpdate(perPage) {
      if (this.perPageEnabled) {
        this.$emit('per-page:update', perPage)
      }
    },
    rowClass(item, type) {
      return 'table-danger'
    },
    onRowRemove(scope) {
      this.deleteScope = scope;
      this.display = !this.display;
    },
    onPlaygroundPhotoUploaded(scope, item) {
      this.$emit('on:playground-photo-uploaded', {photo: scope, row: item})
    },
    onRowClick(scope) {
      this.$emit('row-clicked', scope);
    },
    onBtnDeleteClick(scope) {
      this.deleteScope = scope;
      this.display = !this.display;
    },
    onCancel() {
      this.hide = !this.hide;
    },
    onConfirm() {
      this.isDeleting = true;
      this.hide = !this.hide;

      this.$emit('on:' + this.identifier + ':delete', this.deleteScope);
    },
    onBtnEditClick(scope) {
      this.$emit('on:data-table-btn:edit', scope);
    },
    onBtnDataTableCheck(scope) {
      this.$emit('on:data-table-btn:radio', scope);
    },
    onBtnDataTableCheckbox(scope) {
      this.$emit('on:data-table-btn:checkbox', scope);
    },
    onCheckboxSelectionSelected(scope) {
      this.$emit('on:checkbox-selected', scope);
    },
    onRadioCheck(scope) {
      this.$emit('on:radio-button-check', scope);
    },
    onRadioRateCheck(scope) {
      this.$emit('on:radio-button-rate-check', scope);
    }
  },
  mounted() {
    this.pageCurrent = this.currentPage;
    this.perPageCurrent = this.perPage;
  }
};
</script>
<style>
.dataTable thead th {
  font: normal normal normal 13px Arial;
  letter-spacing: 0px;
  color: #67696b;
  text-align: left;
  opacity: 1;
  background: #E3E9EF;
  vertical-align: middle;
}

table.dataTable input, table.dataTable select {
  border: unset;
  height: 25px;
}

.dataTable thead th:last-child {
  font: normal normal normal 13px Arial;
  letter-spacing: 0px;
  color: #67696b;
  text-align: center;
  opacity: 1;
  background: #E3E9EF;
}

.dataTable thead {
  background: #E3E9EF 0% 0% no-repeat padding-box;
  opacity: 0.8;
}

.table thead {
  border-bottom: #1b1e21;
}

.table thead th {
  border-bottom: unset;
}

.table thead th:first-child {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.table thead th:last-child {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.table-wrap {
  width: 100%;
  overflow-x: auto;
}

.side-panel {
  max-width: 90px;
}

.table td {
  color: #52575D;
  text-align: left;
  font: normal normal normal 13px arial;
  letter-spacing: 0;
  opacity: 1;
}

.table {
  overflow-y: visible;
}

.dataTable tbody td {
  background: #FFF 0% 0% no-repeat padding-box;
}

.datatable-vue table {
  border: unset;
}

.datatable-vue table tbody tr:nth-child(odd) td:first-child {
  background: #FFF 0% 0% no-repeat padding-box;
  border: unset;
  text-align: left;
}

.datatable-vue table tbody tr:nth-child(even) td:first-child {
  background-color: #FFF;
  border: unset;
  text-align: left;
}

.datatable-vue table tbody tr:nth-child(odd) td:last-child {
  background: #FFF 0% 0% no-repeat padding-box;
  border: unset;
  text-align: center;
}

.datatable-vue table tbody tr:nth-child(even) td:last-child {
  background-color: #FFF;
  border: unset;
  text-align: center;
}

.table th, .table td {
  padding: 0.55rem;
  vertical-align: top;
  text-align: left;
  border: unset;
}

.table > tbody > tr.no-hover:hover {
  background-color: var(--hover);
}

.datatable-vue table tbody tr:nth-child(even):hover td {
  background-color: var(--hover);
  cursor: var(--cursor);
}

.datatable-vue table tbody tr:nth-child(odd):hover td {
  background-color: var(--hover);
  cursor: var(--cursor);
}

.datatable-vue table tbody tr:nth-child(even):hover td:first-child {
  background-color: var(--hover);
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.datatable-vue table tbody tr:nth-child(even):hover td:last-child {
  background-color: var(--hover);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.datatable-vue table tbody tr:nth-child(odd):hover td:first-child {
  background-color: var(--hover);
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.datatable-vue table tbody tr:nth-child(odd):hover td:last-child {
  background-color: var(--hover);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.pagination-sm .page-item .page-link {
  border: unset;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #52575D;
  background-color: #fff;
  border: unset;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  line-height: 1.0;
  font-size: 0.87rem;
  border-radius: 6px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0B2772;
  border-color: #0B2772;
}

.no {
  width: 96px !important;
  height: 38px !important;
}

.delete-first-col {
  width: 20px !important;
  text-align: center;
}

.selection-checkbox-td {
  width: 80px !important;
}

.datatable-vue {
  margin: 8px 0;
}

.table.b-table-selectable > tbody > tr.b-table-row-selected > td,
.table.b-table-selectable > tbody > tr.b-table-row-selected > th {
  background-color: var(--info);
}

@media only screen and (max-width: 991px) {
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
    content: attr(data-label);
    width: 40%;
    float: left;
    text-align: left;
    overflow-wrap: break-word;
    font-weight: bold;
    font-style: normal;
    padding: 0 calc(1rem / 2) 0 0;
    margin: 0;
  }

  .datatable-vue table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
    border-bottom: 2px solid #d8d9d9;
  }

  .datatable-vue table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
    border-bottom: 2px solid #d8d9d9;
  }

  .datatable-vue table tbody tr:nth-child(odd):last-child {
    background-color: #f9f9f9;
    border-bottom: unset;
  }

  .datatable-vue table tbody tr:nth-child(even):last-child {
    background-color: #f9f9f9;
    border-bottom: unset;
  }
}

.table-responsive-overflow {
  overflow-y: visible !important;
  overflow-x: visible !important;
}
</style>
