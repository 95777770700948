<template>
  <b-modal
      :id="'modal-delete-'+ modalId"
      hide-header
      hide-footer
      hide-header-close
      size="lg"
      header-class="my-second-class"
      :body-bg-variant="'gray-lighten'"
  >
    <b-row>
      <b-col align="center" class="modal-title-class">
        {{ $t(title) }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3">
    </div>
    <b-row>
      <b-col align="center" class="">
        {{ $t(text) }}
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col class="pr-0" align="right">
        <d-button
            :text="'general.actions.cancel'"
            :class="'d-btn-sm d-btn btn d-btn-danger font-text-title'"
            @on:button-click="$emit('on:cancel')"
        />
      </b-col>
      <b-col class="pr-0" align="left">
        <d-button
            :text="'general.actions.confirm'"
            :class="'d-btn-sm font-text-title d-btn btn d-btn-success'"
            @on:button-click="$emit('on:confirm')"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>

export default {
  props: {
    text: {
      type: String,
      default: 'general.actions.delete-modal-text'
    },
    modalId: {
      type: String,
      default: 'default'
    },
    title: {
      type: String,
      default: 'general.actions.delete-modal-title'
    },
    display: {
      type: Boolean,
      default: false,
    },
    hide: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    display: function (changes) {
      this.$bvModal.show('modal-delete-'+ this.modalId);
    },
    hide: function (changes) {
      this.$bvModal.hide('modal-delete-'+ this.modalId);
    }
  },
  beforeDestroy() {
    try{
    } catch (e){}
  },
}
</script>
<style scoped>
.modal-title-class {
  text-align: center;
  font: normal normal 500 30px Avenir;
  letter-spacing: 0px;
  color: #3C3D43;
  opacity: 1;
}
/deep/ .modal {
  top: 100px
}

/deep/ .modal-content {
  border-radius: 8px;
  width: calc(100% - 150px);
  left: 100px;
}

/deep/ .modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  border-radius: 8px;
}

</style>
