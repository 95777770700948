export const tableComponents = {
    BusyTable: () => import('@table/BusyTable'),
    HeadCheckBox: () => import('@table/head/HeadCheckBox'),
    HeadIconLabel: () => import('@table/head/IconLabel'),
    HeadCollapseLabel: () => import('@table/head/CollapseLabel'),
    HeadPhotoLabel: () => import('@table/head/HeadPhotoLabel'),
    CellPreviewCheckBox: () => import('@table/cell/PreviewCheckBox'),
    CellLabelDate: () => import('@table/cell/LabelDate'),
    CellCheckbox: () => import('@table/cell/Checkbox'),
    CellCollapseLabel: () => import('@table/cell/CollapseLabel'),
    CellLink: () => import('@table/cell/Link'),
    CellStar: () => import('@table/cell/Star'),
    CellCustom: () => import('@table/cell/Custom'),
    CellFromDate: () => import('@table/cell/FromDate'),
    CellToDate: () => import('@table/cell/ToDate'),
    CellSingleSelection: () => import('@table/cell/SingleSelection'),
    CellSingleSelectionRate: () => import('@table/cell/SingleSelectionRate'),
    CellSelectionCheckbox: () => import('@table/cell/SelectionCheckbox'),
    CellActions: () => import('@table/cell/Actions'),
    CellPlusIcon: () => import('@table/cell/PlusIcon'),
    CellMinusIcon: () => import('@table/cell/MinusIcon'),
    CellSelect: () => import('@table/cell/Select'),
    CellBlockLabel: () => import('@table/cell/block/Label'),
    CellBlockStartAt: () => import('@table/cell/block/StartAt'),
    CellBlockEndAt: () => import('@table/cell/block/EndAt'),
    CellBlockInstalmentPercentage: () => import('@table/cell/block/InstalmentPercentage'),
    CellBlockStepClientBookingDuration: () => import('@table/cell/block/StepClientBookingDuration.vue'),
    CellBlockStepClubBookingDuration: () => import('@table/cell/block/StepClubBookingDuration.vue'),
}
